import React, { useEffect, useState } from 'react';

function Home() {

    return (
        <div>
            <h1>Clinician AI</h1>
            <p>This service automatically transcribes your audio files and generates notes based on the transcriptions.</p>

            <h2>Frequently Asked Questions</h2>
            <dl>
                <dt>How does this service work?</dt>
                <dd>We use advanced AI to transcribe your audio files and generate useful notes.</dd>

                <dt>Is my data secure?</dt>
                <dd>Yes, we take your privacy and data security very seriously. Read our privacy policy for more information.</dd>

                <dt>What is supported?</dt>
                <dd>We support notes generation on .mp3 audio files up to 90 minutes long, and between at most 2 people.</dd>

                <dt>What browsers are supported?</dt>
                <dd>We currently support Google Chrome on Desktop. Mobile compatibility is planned in the future.</dd>

            </dl>

            <h2>Data Privacy and HIPAA Protection</h2>
            <p>At Clinician AI, we prioritize the security and privacy of your data. We adhere to the standards set by the Health Insurance Portability and Accountability Act (HIPAA) to ensure the confidentiality and safety of your health information.</p>
            <p>Our third-party vendors, OpenAI and Rev.ai, are also committed to data protection. OpenAI's data privacy policy can be found <a href="https://openai.com/api-data-privacy" target="_blank" rel="noopener noreferrer">here</a>, and Rev.ai's security information can be found <a href="https://www.rev.ai/security" target="_blank" rel="noopener noreferrer">here</a>.</p>

        </div>
    );
}

export default Home;
