import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, Checkbox, TablePagination, CircularProgress, IconButton, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Box from '@mui/material/Box';
import GetAppIcon from "@mui/icons-material/GetApp";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import { useAuth0 } from '@auth0/auth0-react';

function CompletedTable() {
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isLoading, setIsLoading] = useState(true);
    const [expandedRow, setExpandedRow] = useState(null);
    const [expandedRowData, setExpandedRowData] = useState(null);
    const { user, isLoading: isAuthLoading } = useAuth0();

    const isSelected = (id) => selected.indexOf(id) !== -1;

    useEffect(() => {
        if (expandedRow !== null) {
            const storage = getStorage();
            const storageRef = ref(storage, `Users/${user.sub}/requests/${expandedRow}/final_notes.txt`);

            getDownloadURL(storageRef)
                .then((url) => {
                    return fetch(url);
                })
                .then((response) => {
                    return response.text();
                })
                .then((text) => {
                    setExpandedRowData(text);
                });
        }
    }, [expandedRow, user]);

    useEffect(() => {
        if(!isAuthLoading && user) {
            const db = getFirestore();
            const q = query(
                collection(db, "Users", user.sub, "requests"),
                where("status", "in", ["completed", "Notes Generation Completed"])
            );
            getDocs(q)
                .then((querySnapshot) => {
                    const completedRequests = [];
                    querySnapshot.forEach((doc) => {
                        const data = doc.data();
                        data.createdAt = data.createdAt.toDate();
                        data.updatedAt = data.updatedAt.toDate();
                        completedRequests.push(data);
                    });
                    completedRequests.sort((a, b) => b.createdAt - a.createdAt);

                    setData(completedRequests);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                    setIsLoading(false);
                });
        }
    }, [user, isAuthLoading]);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        event.stopPropagation();

        const selectedIndex = selected.indexOf(id);

        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else {
            newSelected = newSelected.concat(selected.filter(item => item !== id));
        }

        setSelected(newSelected);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleExpandClick = (id) => {
        setExpandedRow(expandedRow !== id ? id : null);
    };

    return (
        <div style={{margin: '20px 0'}}>
            <Typography variant="h6" component="div" gutterBottom>
                Completed Requests
            </Typography>
            <Toolbar>
                <IconButton
                    aria-label="download"
                    disabled={selected.length === 0}
                    style={{marginRight: '20px'}}
                    onClick={async () => {
                        // alert(`Download action applied to rows: ${selected.join(', ')}`);
                        const fileUrls = selected.map(id => {
                            const row = data.find(row => row.id === id);
                            return row.finalNotesUrl;
                        });
                        // console.log("URL #1" + fileUrls[0] + "\n");
                        // console.log("URL #2" + fileUrls[1] + "\n");

                        // Special case for a single file
                        if (fileUrls.length === 1) {
                            const response = await fetch(fileUrls[0]);
                            const blob = await response.blob();
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'note.txt'); // or any other extension
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        } else {
                            const encodedFileUrls = fileUrls.map(url => encodeURIComponent(url));

                            const response = await fetch(`https://api.clinicianai.care/downloadMultiple/v1/download-multiple?fileUrls=${encodedFileUrls.join(',')}`);

                            if (!response.ok) {
                                console.error(`HTTP error from /download-multiple - status: ${response.status}`);
                            } else {
                                const blob = await response.blob();
                                const url = window.URL.createObjectURL(blob);
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'notes.zip'); // or any other extension
                                document.body.appendChild(link);
                                link.click();
                                link.remove();
                            }
                        }
                    }}
                >
                    <GetAppIcon />
                </IconButton>

            </Toolbar>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{fontWeight: 'bold'}} ></TableCell>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < data.length}
                                    checked={data.length > 0 && selected.length === data.length}
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                            {/*<TableCell style={{fontWeight: 'bold'}} >ID</TableCell>*/}
                            <TableCell style={{fontWeight: 'bold'}} >Status</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} >Document Name</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} >Description</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} >Created At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ?
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                            :
                            data.length > 0 ?
                                data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    const isItemSelected = isSelected(row.id);
                                    return (
                                        <>
                                            <TableRow
                                                key={row.id}
                                                hover
                                                onClick={(event) => handleClick(event, row.id)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                            >
                                                <TableCell>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleExpandClick(row.id);
                                                        }}
                                                    >
                                                        {expandedRow === row.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleClick(event, row.id);
                                                        }}
                                                        checked={isItemSelected}
                                                    />

                                                </TableCell>

                                                {/*<TableCell>{row.id}</TableCell>*/}
                                                <TableCell>{row.status}</TableCell>
                                                <TableCell>{row.docName}</TableCell>
                                                <TableCell>{row.description}</TableCell>
                                                <TableCell>{row.createdAt.toString()}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                                    <Collapse in={expandedRow === row.id} timeout="auto" unmountOnExit>
                                                        <Box margin={1}>
                                                            <Typography variant="h6" gutterBottom component="div">
                                                                Notes
                                                            </Typography>
                                                            {expandedRow === row.id && expandedRowData &&
                                                                expandedRowData.split('\n').map((line, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {line}
                                                                        <br/>
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                })
                                :
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <Box sx={{ m: 2 }}>No data available</Box>
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </div>
    );
}

export default CompletedTable;
