import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Paper,
    TableHead,
    TableRow,
    Checkbox,
    TablePagination,
    CircularProgress,
    IconButton, Toolbar
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { getFirestore, collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import Box from '@mui/material/Box';
import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";

function FailedTable() {
    const { user, isLoading: isAuthLoading } = useAuth0();
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isLoading, setIsLoading] = useState(true);

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDeleteClick = async () => {
        const db = getFirestore();
        // Change status of selected rows to "Abandoned"
        const promises = selected.map((id) => {
            const docRef = doc(db, "Users", user.sub, "requests", id);
            return updateDoc(docRef, { status: "archived" });
        });

        try {
            await Promise.all(promises);
            // alert(`Delete action applied to rows: ${selected.join(', ')}`);
            // After deleting, deselect all and refresh data
            setSelected([]);
            fetchData();
        } catch (err) {
            console.error("Error updating documents: ", err);
        }
    };

    useEffect(() => {
        fetchData();
    }, [user, isAuthLoading]);

    const fetchData = () => {
        if (!isAuthLoading && user) {
            const db = getFirestore();
            const q = query(collection(db, "Users", user.sub, "requests"), where("status", "==", "failed"));
            getDocs(q)
                .then((querySnapshot) => {
                    const failedRequests = [];
                    querySnapshot.forEach((doc) => {
                        const data = doc.data();
                        data.createdAt = data.createdAt.toDate();
                        data.updatedAt = data.updatedAt.toDate();
                        failedRequests.push(data);
                    });
                    failedRequests.sort((a, b) => b.createdAt - a.createdAt);

                    setData(failedRequests);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                    setIsLoading(false);
                });
        }
    };

    return (
        <div style={{margin: '20px 0'}}>
            <Typography variant="h6" component="div" gutterBottom>
                Failed Requests
            </Typography>
            <Toolbar>
                <IconButton
                    aria-label="delete"
                    disabled={selected.length === 0}
                    style={{marginRight: '20px'}}
                    onClick={handleDeleteClick}
                >
                    <DeleteIcon />
                </IconButton>
            </Toolbar>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < data.length}
                                    checked={data.length > 0 && selected.length === data.length}
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                            {/*<TableCell style={{fontWeight: 'bold'}} >ID</TableCell>*/}
                            <TableCell style={{fontWeight: 'bold'}} >Status</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} >Failure Reason</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} >Document Name</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} >Description</TableCell>
                            <TableCell style={{fontWeight: 'bold'}} >Created At</TableCell>
                            {/*<TableCell style={{fontWeight: 'bold'}} >Updated At</TableCell>*/}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ?
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                            :
                            data.length > 0 ?
                                data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    const isItemSelected = isSelected(row.id);
                                    return (
                                        <TableRow
                                            key={row.id}
                                            selected={isItemSelected}
                                            onClick={(event) => handleClick(event, row.id)}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox checked={isItemSelected} />
                                            </TableCell>
                                            {/*<TableCell>{row.id}</TableCell>*/}
                                            <TableCell>{row.status}</TableCell>
                                            <TableCell>{row.error}</TableCell>
                                            <TableCell>{row.docName}</TableCell>
                                            <TableCell>{row.description}</TableCell>
                                            <TableCell>{row.createdAt.toString()}</TableCell>
                                        </TableRow>
                                    );
                                })
                                :
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <Box sx={{ m: 2 }}>No data available</Box>
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </div>
    );
}

export default FailedTable;
