import React, { useState } from 'react';
import axios from 'axios';
import {
    Container,
    Box,
    Typography,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    CircularProgress,
    Backdrop,
    Slide, DialogContentText, Alert, Snackbar
} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import PauseIcon from '@mui/icons-material/Pause';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import './RecordMeeting.css';
import DynamicAudioWaveform from "./WaveformUtils/DynamicAudioWaveform";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAuth0 } from "@auth0/auth0-react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import { AudioVisualizer } from "react-audio-visualize";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide mountOnEnter unmountOnExit direction="up" ref={ref} {...props} />;
});

// SpinnerOverlay component
function SpinnerOverlay() {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                <CircularProgress color="inherit" size={80} />
                <Typography variant="h4" component="div">File currently uploading...</Typography>
            </Box>
        </Backdrop>
    );
}

function RecordMeeting() {
    const [currentLayer, setCurrentLayer] = useState('default'); // Track current UI layer
    const [lastActiveLayer, setLastActiveLayer] = useState(null); // Track last active layer before delete confirmation
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioStream, setAudioStream] = useState(null);
    const [audioBlob, setAudioBlob] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [timer, setTimer] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [docName, setDocName] = useState("");
    const [docDesc, setDocDesc] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [errorMessage, setErrorMessage] = useState(null);
    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');
    const navigate = useNavigate();

    const { user } = useAuth0(); // This hook should be called inside the component
    const backendUrl = 'https://api.clinicianai.care'; // Define backendUrl here
    // TEST-ONLY
    // const backendUrl = 'https://localhost:3000'; // TEST



    const handleRecordButtonClick = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            alert('Media Devices will not work on this browser.');
            return;
        }
        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true});
            setAudioStream(stream);
            const recorder = new MediaRecorder(stream, {mimeType: 'audio/webm'});
            setMediaRecorder(recorder);
            recorder.start();
            setCurrentLayer('recording');
            startTimer();
        } catch (error) {
            console.error('Error accessing the microphone:', error);
        }
    };

    const startTimer = () => {
        setTimer(setInterval(() => {
            setElapsedTime(prevTime => prevTime + 1);
        }, 1000));
    };

    const stopTimer = () => {
        if (timer) {
            clearInterval(timer);
            setTimer(null);
        }
    };

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((time % 3600) / 60).toString().padStart(2, '0');
        const seconds = (time % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    const handlePauseButtonClick = () => {
        if (mediaRecorder && mediaRecorder.state === 'recording') {
            mediaRecorder.pause();
            setCurrentLayer('paused');
            stopTimer();
        }
    };

    const handleResumeButtonClick = () => {
        if (mediaRecorder && mediaRecorder.state === 'paused') {
            mediaRecorder.resume();
            setCurrentLayer('recording');
            startTimer();
        }
    };

    const handlePreviewButtonClick = () => {
        // Transition to preview layer logic goes here
        if (mediaRecorder && (mediaRecorder.state === 'recording' || mediaRecorder.state === 'paused')) {
            mediaRecorder.stop();
            mediaRecorder.ondataavailable = (e) => {
                const newAudioBlob = e.data;
                setAudioBlob(newAudioBlob);  // Update the state with the new blob
                const newAudioUrl = URL.createObjectURL(newAudioBlob);  // Create URL from the new blob directly
                setAudioUrl(newAudioUrl);  // Set the new URL
            };
            setCurrentLayer('preview');
        }
    };

    const handleDownloadButtonClick = () => {
        if (audioBlob) {
            // Create a URL for the audio blob
            const audioUrl = URL.createObjectURL(audioBlob);

            // Create a temporary <a> element to trigger the download
            const downloadLink = document.createElement('a');
            downloadLink.href = audioUrl;
            downloadLink.download = 'recorded_audio.webm';
            document.body.appendChild(downloadLink);

            // Trigger the download
            downloadLink.click();

            // Clean up
            document.body.removeChild(downloadLink);

            // Set Snackbar message for download
            setSnackbarMessage('Download initiated successfully.');
            setSnackbarSeverity('info');
            setSnackbarOpen(true);
        }
    };


    const handleGenerateNotesButtonClick = () => {
        setShowForm(true);
    };

    const handleDeleteButtonClick = () => {
        setLastActiveLayer(currentLayer); // Store the current layer before changing to delete confirmation
        setCurrentLayer('confirmDelete');
    };

    const handleConfirmDelete = () => {
        if (audioStream) {
            audioStream.getTracks().forEach(track => track.stop());  // Stop the audio stream
            setAudioStream(null);
            setAudioBlob(null);
            stopTimer();
            setElapsedTime(0); // Reset the time on stopping the recording
        }
        setCurrentLayer('default');
        setAttemptedSubmit(false);

        // Set Snackbar message for delete confirmation
        setSnackbarMessage('Recording deleted successfully.');
        setSnackbarSeverity('info');
        setSnackbarOpen(true);
    };

    const handleCancelDelete = () => {
        setCurrentLayer(lastActiveLayer); // Return to the last active layer
    };

    const layerColorMapper = (currentLayer) => {
        switch (currentLayer) {
            case 'default':
                return 'gray';
            case 'recording':
                return 'blue';
            case 'paused':
                return '#F25363';
            case 'confirmDelete':
                return 'white';
            case 'preview':
                return '#0156FD';
            default:
                return 'gray';
        }
    };

    const handleFormSubmit = async () => {
        setAttemptedSubmit(true);
        if (!docName.trim() || !docDesc.trim()) {
            setErrorMessage("Both fields are required.");
            setIsLoading(false);
            return;
        }
        setAttemptedSubmit(false);
        setErrorMessage(null); // Clear any existing error message
        setIsLoading(true);
        let uploaded = false;
        let urlResponse;

        try {
            // Fetch the audio data from the audioUrl
            const audioData = await fetch(audioUrl).then((res) => res.arrayBuffer());

            // Request a pre-signed URL
            urlResponse = await axios.post(`${backendUrl}/getPresignedUrl/v1/get-presigned-url`, {
                fileName: 'recorded_audio.webm',
                fileType: 'audio/webm',
            });

            // Get the URL from the response
            const presignedUrl = urlResponse.data.url;

            // Use the pre-signed URL to upload the WAV file to S3
            await axios.put(presignedUrl, audioData, {
                headers: {
                    'Content-Type': 'audio/webm',
                },
            });

            // Mark as uploaded
            uploaded = true;

        } catch (error) {
            setIsLoading(false);
            console.error('Error in pre-signed URL request:', error);
            setShowForm(false);
            setSnackbarMessage('Error in file upload: pre-signed URL request failed');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return; // Return early to stop execution
        }

        if (uploaded) {
            try {
                // Notify the server that the upload is complete
                await axios.post(`${backendUrl}/notesGeneration/v1/notes-generation`, {
                    fileName: urlResponse.data.file,
                    docName: docName, // update as needed
                    description: docDesc, // update as needed
                    userId: user.sub,
                });
                setSnackbarMessage('Request submitted!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            } catch (error) {
                setIsLoading(false);
                console.error('Error in /notes-generation request:', error);
                setShowForm(false);
                setSnackbarMessage('Error in Notes Generation stage!');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        }
        setIsLoading(false);
        setShowForm(false);
    };

    return (
        <>
            {isLoading && <SpinnerOverlay />}
            <IconButton
                sx={{ top: 0, left: 0 }}
                onClick={() => { navigate('/create-notes-options'); }}
            >
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h3" style={{ padding: 50, display: 'flex', justifyContent: 'center', color: 'black', marginBottom: '8px' }}>
                Patient Session Recorder
            </Typography>
            <Container maxWidth="lg" style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center', // Keeps content at the bottom of the box
                    height: '40vh',
                    width: '70%',
                    backgroundColor: layerColorMapper(currentLayer),
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    // padding: '20px',
                    boxSizing: 'border-box',
                    position: 'relative',
                }}>
                    {currentLayer === 'recording' && (
                        <Typography variant="p" style={{
                            position: 'absolute', // Absolutely position the RECORDING text
                            top: '15px', // Adjust as needed to place it at the top of the modal
                            left: '50%',
                            transform: 'translateX(-50%)', // Center the text
                            color: 'white', // White text to stand out against the blue background
                        }}>
                            RECORDING
                        </Typography>
                    )}
                    {currentLayer === 'recording' && (
                        <DynamicAudioWaveform audioStream={audioStream} />
                    )}
                    {currentLayer === 'preview' && (
                        <Typography variant="p" style={{
                            position: 'flex', // Absolutely position the RECORDING text
                            color: 'white', // White text to stand out against the blue background
                        }}>
                            Preview player is currently in development.
                            Please use the below options.
                        </Typography>
                    )}
                    {currentLayer === 'paused' && (
                        <Typography variant="p" style={{
                            position: 'absolute', // Absolutely position the RECORDING text
                            top: '15px', // Adjust as needed to place it at the top of the modal
                            left: '50%',
                            transform: 'translateX(-50%)', // Center the text
                            color: 'white', // White text to stand out against the blue background
                        }}>
                            PAUSED
                        </Typography>
                    )}
                    {currentLayer === 'preview' && audioBlob && (
                        <AudioVisualizer
                            blob={audioBlob}
                            width={840}
                            height={250}
                            barWidth={3}
                            gap={2}
                            barColor={'black'}
                            style={{
                                width: '100%'
                            }}
                        />
                    )}
                    {currentLayer === 'preview' && (
                        <Typography variant="p" style={{
                            position: 'absolute', // Absolutely position the RECORDING text
                            top: '20px', // Adjust as needed to place it at the top of the modal
                            left: '50%',
                            fontSize: '16px',
                            transform: 'translateX(-50%)', // Center the text
                            color: 'white', // White text to stand out against the blue background
                        }}>
                            {formatTime(elapsedTime)}
                        </Typography>
                    )}
                    {currentLayer === 'default' && (
                        <Box style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <Typography variant="subtitle1" style={{ color: 'white', marginBottom: '20px' }}>
                                Click below to start recording.
                            </Typography>
                            <Button
                                onClick={handleRecordButtonClick}
                                variant="contained"
                                style={{
                                    backgroundColor: '#EE3524',
                                    color: 'white',
                                    marginBottom: '20px',
                                    padding: '10px 20px',
                                    textTransform: 'none',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    letterSpacing: '2px'
                                }}
                                startIcon={<MicIcon />}
                            >
                                RECORD
                            </Button>
                        </Box>
                    )}
                    {currentLayer === 'recording' && (
                        <Box style={{
                            display: 'flex',
                            flexDirection: 'column', // Changed to column to stack the timer above buttons
                            justifyContent: 'center', // Center the container vertically and horizontally
                            alignItems: 'center',
                            position: 'absolute',
                            left: '50%',
                            bottom: '20px', // Position the container 20px from the bottom
                            transform: 'translateX(-50%)', // Center the container with respect to its parent
                        }}>
                            {/*<div style={{*/}
                            {/*    width: '100%', // Ensures the div takes full width of its parent*/}
                            {/*    height: '100px', // Fixed height; adjust as necessary*/}
                            {/*}}>*/}
                            {/*    <DynamicAudioWaveform audioStream={audioStream} />*/}
                            {/*</div>*/}
                            <div style={{
                                color: 'white', // Color of the timer to match the button text color
                                fontSize: '16px', // Slightly larger font for better visibility
                                marginBottom: '15px', // Space between timer and buttons
                            }}>
                                {formatTime(elapsedTime)} {/* Call the function to format and display elapsed time */}
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <Button
                                    onClick={handlePauseButtonClick}
                                    variant="contained"
                                    color="primary"
                                    style={{ backgroundColor: 'white', color: '#5A2EDE', padding: '10px 20px', fontSize: '16px', letterSpacing: '1px', width: '220px', height: '50px'}}
                                    startIcon={<PauseIcon />}
                                >
                                    Pause
                                </Button>
                                <IconButton
                                    onClick={handleDeleteButtonClick}
                                    style={{ backgroundColor: 'blue', color: 'white', padding: '10px', marginLeft: '10px' }} // marginLeft ensures space between the buttons
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </Box>
                    )}
                    {currentLayer === 'confirmDelete' && (
                        <>
                            <Typography variant="h6" style={{ textAlign: 'center', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                Are you sure you want to delete your recording?
                            </Typography>
                            <Box style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center', // Center buttons horizontally
                                marginBottom: '20px', // Keep space between buttons and the bottom edge
                            }}>
                                <Button
                                    onClick={handleConfirmDelete}
                                    variant="contained"
                                    style={{
                                        width: '150px',
                                        backgroundColor: 'blue',
                                        color: 'white',
                                        marginRight: '60px',
                                        padding: '10px 20px',
                                        textTransform: 'none',
                                        fontSize: '16px',
                                    }}
                                >
                                    DELETE
                                </Button>
                                <Button
                                    onClick={handleCancelDelete}
                                    variant="outlined"
                                    style={{
                                        width: '150px',
                                        borderColor: 'blue',
                                        color: 'blue',
                                        padding: '10px 20px',
                                        textTransform: 'none',
                                        fontSize: '16px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    NOT SURE
                                </Button>
                            </Box>
                        </>
                    )}
                    {currentLayer === 'paused' && (
                        <Box style={{
                            display: 'flex',
                            flexDirection: 'column', // Stack elements vertically
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            left: '50%',
                            bottom: '20px',
                            transform: 'translateX(-50%)',
                            width: '100%', // Ensure full width to center content properly
                        }}>
                            <div style={{
                                color: 'white', // Assuming white for visibility against a likely darker background
                                fontSize: '16px', // Slightly larger font for better visibility
                                marginBottom: '15px', // Space between timer and buttons
                            }}>
                                {formatTime(elapsedTime)} {/* Displaying the formatted elapsed time */}
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center', // Center the buttons horizontally
                                width: '100%', // Full width for inner container to align with outer settings
                            }}>
                                <Button
                                    onClick={handleResumeButtonClick}
                                    variant="contained"
                                    style={{ fontSize: '16px', letterSpacing: '1px', width: '220px', height: '50px', border: '1px solid white', backgroundColor: '#F25363', color: 'white', marginRight: '10px' }}
                                >
                                    RESUME
                                </Button>
                                <Button
                                    onClick={handlePreviewButtonClick}
                                    variant="contained"
                                    style={{ fontSize: '16px', letterSpacing: '1px', width: '220px', height: '50px', backgroundColor: 'white', color: '#5A2EDE', marginRight: '10px' }}
                                >
                                    PREVIEW
                                </Button>
                                <IconButton
                                    onClick={handleDeleteButtonClick}
                                    style={{ color: 'white', marginLeft: '10px' }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </Box>
                    )}
                    {currentLayer === 'preview' && (
                        <Box style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            left: '50%',
                            bottom: '20px',
                            transform: 'translateX(-50%)',
                            width: '100%', // Ensure full width to center content properly
                        }}>
                            <Button
                                onClick={handleGenerateNotesButtonClick}
                                variant="contained"
                                style={{ fontSize: '16px', letterSpacing: '1px', width: '220px', height: '50px', backgroundColor: 'white', color: '#5A2EDE', marginRight: '10px' }}
                            >
                                GENERATE NOTES
                            </Button>
                            <IconButton
                                onClick={handleDownloadButtonClick}
                                style={{ color: 'white' }}
                            >
                                <DownloadIcon />
                            </IconButton>
                            <IconButton
                                onClick={handleDeleteButtonClick}
                                style={{ color: 'white' }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    )}
                </Box>
            </Container>
            {showForm && !isLoading && (
                <Dialog
                    open={true}
                    onClose={() => setShowForm(false)}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>Submit your recording for notes generation.</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Note: Upload can take a few minutes</DialogContentText>

                        <TextField
                            autoFocus
                            margin="dense"
                            label="Document Name *"
                            fullWidth
                            value={docName}
                            onChange={(e) => setDocName(e.target.value)}
                            error={attemptedSubmit && !docName.trim()}
                        />
                        <TextField
                            margin="dense"
                            label="Document Description *"
                            fullWidth
                            value={docDesc}
                            onChange={(e) => setDocDesc(e.target.value)}
                            error={attemptedSubmit && !docDesc.trim()}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setShowForm(false); setAttemptedSubmit(false); }}>Cancel</Button>
                        <Button onClick={handleFormSubmit}>Submit</Button>
                    </DialogActions>
                </Dialog>
            )}
            <Typography variant="caption" style={{ color: 'black', textAlign: 'center', display: 'block', marginTop: '20px' }}>
                Recommended browsers: Google Chrome (Desktop)
            </Typography>
            <Snackbar
                open={!!errorMessage}
                autoHideDuration={6000}
                onClose={() => setErrorMessage(null)}
                message={errorMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                ContentProps={{
                    style: { backgroundColor: 'red', color: 'white' }
                }}
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%', fontSize: '1.5rem' }}
                    iconMapping={{
                        success: <CheckCircleIcon fontSize="large" />,
                        error: <ErrorIcon fontSize="large" />,
                        warning: <WarningIcon fontSize="large" />,
                        info: <InfoIcon fontSize="large" />
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default RecordMeeting;