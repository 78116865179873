import React, {useEffect, useState} from 'react';
import {List, ListItem, ListItemIcon, ListItemText, Divider, Button, Box, Paper} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {doc, setDoc, getDoc, addDoc, collection} from "firebase/firestore";
import {db} from "../App";

function Navbar() {
    const { user, isAuthenticated, isLoading, logout } = useAuth0();
    const [userChecked, setUserChecked] = useState(false);

    useEffect(() => {
        if (!isLoading && user && !userChecked) {
            const fetchUser = async () => {
                // Create a reference to the document you want to check or create.
                const docRef = doc(db, "Users", user.sub);

                // Fetch the document and check if it exists
                const docSnap = await getDoc(docRef);

                // If the document doesn't exist, create it.
                if (!docSnap.exists()) {
                    await setDoc(docRef, {
                        email: user.email,
                        name: user.name,
                    });

                    // Now, create a dummy document in the Requests collection
                    const requestsCollection = collection(db, "Users", user.sub, "requests");
                    await addDoc(requestsCollection, { dummy: true });
                }
                setUserChecked(true);
            };

            fetchUser();
        }
    }, [isLoading, user, db, userChecked]);


    if (isLoading) {
        return <div>Loading...</div>;
    }


    return (
        <Paper style={{ borderRadius: "0px", position: "fixed", top: 0, height: "100vh", backgroundColor: "#1947ba", width: '15%' }}>
            <List component="nav" style={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '20px' }}>
                <ListItem button component={Link} to="/">
                    <ListItemIcon>
                        <HomeIcon style={{ color: '#ffffff' }} />
                    </ListItemIcon>
                    <ListItemText primary="Home" style={{ color: '#ffffff' }} />
                </ListItem>
                <ListItem button component={Link} to="/create-notes-options">
                    <ListItemIcon>
                        <NoteAddIcon style={{ color: '#ffffff' }} />
                    </ListItemIcon>
                    <ListItemText primary="Create Notes" style={{ color: '#ffffff' }} />
                </ListItem>
                <ListItem button component={Link} to="/history">
                    <ListItemIcon>
                        <HistoryIcon style={{ color: '#ffffff' }} />
                    </ListItemIcon>
                    <ListItemText primary="History" style={{ color: '#ffffff' }} />
                </ListItem>

                {/* This is the spacer */}
                <Box style={{ flexGrow: 1 }} />

                <Divider />

                {isAuthenticated && (
                    <>
                        <ListItem>
                            <ListItemText primary={user.name} style={{ color: '#ffffff', fontWeight: 'bold' }} />
                        </ListItem>
                        <ListItem style={{ marginTop: '-15px' }}>
                            <ListItemText primary={user.email} style={{ color: '#ffffff' }} />
                        </ListItem>
                        <ListItem style={{ marginBottom: '30px' }}>
                            <Button
                                variant="contained"
                                startIcon={<LogoutIcon />}
                                style={{ color: '#1947ba', backgroundColor: '#ffffff' }}
                                // TEST-ONLY
                                // onClick={() => logout({ returnTo: "http://localhost:3001" })}
                                onClick={() => logout({ returnTo: "https://clinicianai.care" })}

                            >
                                Logout
                            </Button>
                        </ListItem>
                    </>
                )}
            </List>
        </Paper>
    );
}

export default Navbar;