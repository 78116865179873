import React, { useState } from 'react';
import InProgressTable from './subcomponents/InProgressTable';
import CompletedTable from './subcomponents/CompletedTable';
import FailedTable from './subcomponents/FailedTable';


const rowData = [
    { id: 1, requestId: '1', createdAt: '2023-05-11', updatedAt: '2023-05-12', status: 'in-progress' },
    { id: 2, requestId: '2', createdAt: '2023-05-12', updatedAt: '2023-05-13', status: 'in-progress' },
    { id: 3, requestId: '3', createdAt: '2023-05-13', updatedAt: '2023-05-14', status: 'in-progress' },
    { id: 4, requestId: '4', createdAt: '2023-05-14', updatedAt: '2023-05-15', status: 'in-progress' },
    { id: 5, requestId: '5', createdAt: '2023-05-15', updatedAt: '2023-05-16', status: 'in-progress' },
    { id: 6, requestId: '6', createdAt: '2023-05-16', updatedAt: '2023-05-17', status: 'completed' },
    { id: 7, requestId: '7', createdAt: '2023-05-17', updatedAt: '2023-05-18', status: 'completed' },
    { id: 8, requestId: '8', createdAt: '2023-05-18', updatedAt: '2023-05-19', status: 'completed' },
    { id: 9, requestId: '9', createdAt: '2023-05-19', updatedAt: '2023-05-20', status: 'completed' },
    { id: 10, requestId: '10', createdAt: '2023-05-20', updatedAt: '2023-05-21', status: 'completed' },
    { id: 11, requestId: '11', createdAt: '2023-05-21', updatedAt: '2023-05-22', status: 'failed' },
    { id: 12, requestId: '12', createdAt: '2023-05-22', updatedAt: '2023-05-23', status: 'failed' },
    { id: 13, requestId: '13', createdAt: '2023-05-23', updatedAt: '2023-05-24', status: 'failed' },
    { id: 14, requestId: '14', createdAt: '2023-05-24', updatedAt: '2023-05-25', status: 'failed' },
    { id: 15, requestId: '15', createdAt: '2023-05-25', updatedAt: '2023-05-26', status: 'failed' },
    { id: 16, requestId: '16', createdAt: '2023-05-21', updatedAt: '2023-05-22', status: 'failed' },
    { id: 17, requestId: '17', createdAt: '2023-05-22', updatedAt: '2023-05-23', status: 'failed' },
    { id: 18, requestId: '18', createdAt: '2023-05-23', updatedAt: '2023-05-24', status: 'failed' },
    { id: 19, requestId: '19', createdAt: '2023-05-24', updatedAt: '2023-05-25', status: 'failed' },
    { id: 20, requestId: '20', createdAt: '2023-05-25', updatedAt: '2023-05-26', status: 'failed' },
];


// const inProgressData = rowData.filter(row => row.status === 'in-progress');
const completedData = rowData.filter(row => row.status === 'completed');
const failedData = rowData.filter(row => row.status === 'failed');

const HistoryPage = () => {
    return (
        <div style={{backgroundColor: '#f5f5f5', paddingRight: '25px'}}>
            <h1>Requests</h1>

            <InProgressTable />
            <CompletedTable data={completedData} />
            <FailedTable data={failedData} />
        </div>
    );
};

export default HistoryPage;
