import React from 'react';
import { Button, Container, Box, Typography, Grid } from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUpload';
import MicIcon from '@mui/icons-material/Mic';
import { useNavigate } from "react-router-dom";

function CreateNotesOptions() {
    const navigate = useNavigate();

    return (
        <Container maxWidth="lg">
            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h5" color="textSecondary" component="p" gutterBottom>
                    Choose one of the options below to begin
                </Typography>
                <Grid sx={{ marginTop: 2 }} container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            startIcon={<MicIcon />}
                            onClick={() => { navigate('/record-meeting'); }}
                            sx={{ height: '400px', fontSize: '24px' }}
                        >
                            Record a Meeting
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            startIcon={<UploadIcon />}
                            onClick={() => { navigate('/upload-audio-file'); }}
                            sx={{ height: '400px', fontSize: '24px' }}
                        >
                            Upload an Audio File
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default CreateNotesOptions;
