import React, { useEffect, useRef } from 'react';

function DynamicAudioWaveform({ audioStream }) {
    const canvasRef = useRef(null);
    const analyserRef = useRef(null);
    const animationFrameRef = useRef(null);

    const setupAudioContext = () => {
        if (!audioStream) return;

        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const source = audioContext.createMediaStreamSource(audioStream);

        source.connect(analyser);
        analyser.fftSize = 256; // Smaller FFT size for faster updates
        analyser.minDecibels = -90;
        analyser.maxDecibels = -10;
        analyser.smoothingTimeConstant = 0.3;


        // analyser.minDecibels = -90; // Lower this value if you want to capture quieter parts
        // analyser.maxDecibels = -10; // Raise this value if you want to reduce the sensitivity


        analyserRef.current = analyser;

        startVisualizing();
    };

    const startVisualizing = () => {
        if (!canvasRef.current || !analyserRef.current) return;

        const canvas = canvasRef.current;
        const analyser = analyserRef.current;
        const canvasContext = canvas.getContext('2d');
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const WIDTH = canvas.width;
        const HEIGHT = canvas.height;

        let shiftAmount = 5; // Shift viewport by this many pixels each frame
        const drawBars = () => {
            requestAnimationFrame(drawBars);

            // Clear a small part of the canvas where new bars will be drawn
            canvasContext.fillStyle = 'blue';  // Background color
            canvasContext.fillRect(WIDTH - shiftAmount, 0, shiftAmount, HEIGHT);

            // Get the frequency data
            const dataArray = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(dataArray);

            const barWidth = (WIDTH / analyser.frequencyBinCount) * 3;
            let x = WIDTH - shiftAmount; // Start drawing at the far right

            // Define the middle of the canvas
            const middle = HEIGHT / 2;

            // Only draw the last few bars based on shiftAmount
            for(let i = 0; i < shiftAmount / barWidth; i++) {
                let barHeight = dataArray[i] * (middle / 128.0);  // Adjust scaling to use half the canvas height

                // Draw bars extending up from the middle
                canvasContext.fillStyle = `white`;  // Bar color
                canvasContext.fillRect(x, middle - barHeight, barWidth, barHeight);

                // Draw bars extending down from the middle
                canvasContext.fillRect(x, middle, barWidth, barHeight);

                x += barWidth + 1;  // Move left for the next bar
            }

            // Shift the entire canvas content to the left
            canvasContext.drawImage(canvas, -barWidth, 0);
        };

        drawBars();


    };

    useEffect(() => {
        setupAudioContext();

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [audioStream]);



    return <canvas ref={canvasRef} width="640" height="100" style={{ width: '100%' }} />;
}

export default DynamicAudioWaveform;
