import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Grid } from '@mui/material';
import Navbar from './components/Navbar';
import Home from './components/HomePage';
import UploadAudioFile from './components/CreateNotesPage/UploadAudioFile';
import CreateNotesOptions from './components/CreateNotesPage/CreateNotesOptions';
import RecordMeeting from './components/CreateNotesPage/RecordMeeting';
import HistoryPage from './components/HistoryPage/HistoryPage';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD-qLxvFqLVoTZNm8TYjX6CNLUSA9cMA2U",
    authDomain: "notes-ai-db.firebaseapp.com",
    projectId: "notes-ai-db",
    storageBucket: "notes-ai-db.appspot.com",
    messagingSenderId: "639625672921",
    appId: "1:639625672921:web:ab045906f608c08b0de6c8",
    measurementId: "G-MBZ6MCF7WZ"
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const db = getFirestore();

function InnerApp() {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
    }, [isLoading, isAuthenticated, loginWithRedirect]);

    if (isLoading || !isAuthenticated) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <div className="App">
                <Grid container style={{ backgroundColor: "#f5f5f5", height: '100vh', overflow: 'auto' }}>
                    <Grid item xs={2}>
                        <Navbar />
                    </Grid>
                    <Grid item xs={10} style={{ paddingLeft: '0%', paddingTop: '20px' }}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/create-notes-options" element={<CreateNotesOptions />} />
                            <Route path="/upload-audio-file" element={<UploadAudioFile />} />
                            <Route path="/record-meeting" element={<RecordMeeting />} />
                            <Route path="/history" element={<HistoryPage />} />
                        </Routes>
                    </Grid>
                </Grid>
            </div>
        </Router>
    );
}

function App() {
    return (
        <Auth0Provider
            domain="login.clinicianai.care"
            clientId="VOXkEg9rxN40okj2Hekt3SJed7qBTzkX"
            // TEST-ONLY
            // authorizationParams={{ redirect_uri: 'http://localhost:3001' }}
            authorizationParams={{ redirect_uri: 'https://clinicianai.care/' }}
        >
            <InnerApp />
        </Auth0Provider>
    );
}
export default App;
