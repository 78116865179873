import React, { useState } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Container,
    Grid,
    IconButton,
    Alert,
    Snackbar,
    Backdrop
} from '@mui/material';
import { styled } from '@mui/system';
import { useDropzone } from 'react-dropzone';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import { CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";




const Input = styled('input')({
    display: 'none',
});

const StyledDropzone = styled('div')(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '60px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: 'gray',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: 'gray',
    outline: 'none',
    transition: 'border .24s ease-in-out',
}));

// SpinnerOverlay component
function SpinnerOverlay() {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                <CircularProgress color="inherit" size={80} />
                <Typography variant="h4" component="div">File currently uploading...</Typography>
            </Box>
        </Backdrop>
    );
}

/**
 * CreateNotes component.
 * @returns {JSX.Element}
 * @constructor
 */
function CreateNotes() {
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState('');
    const [docName, setDocName] = useState('');
    const [description, setDescription] = useState('');
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const { user } = useAuth0();

    const onDrop = React.useCallback((acceptedFiles) => {
        if (acceptedFiles[0] && acceptedFiles[0].type.startsWith('audio/')) {
            setFile(acceptedFiles[0]);
            setFileName(acceptedFiles[0].name);
        } else {
            alert('Only audio files are accepted.');
            setFile(null);
            setFileName('');
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleDocNameChange = (event) => {
        setDocName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleClear = () => {
        setFile(null);
        setFileName('');
    };

    /**
     * Handle the form submission.
     * @param event
     * @returns {Promise<void>}
     */
    const backendUrl = 'https://api.clinicianai.care';
    // TEST-ONLY
    // const backendUrl = 'http://localhost:3000';


    const handleSubmit = async (event) => {
        event.preventDefault();
        setUploading(true);
        setIsLoading(true);

        let uploaded = false;
        let urlResponse;

        try {
            console.log('Getting pre-signed URL...');
            console.log('file.name:', file.name);
            console.log('file.type:', file.type);

            // Request a pre-signed URL
            urlResponse = await axios.post(
                `${backendUrl}/getPresignedUrl/v1/get-presigned-url`,
                {
                    fileName: file.name,
                    fileType: file.type,
                }
            );
            console.log('CLIENT: Received pre-signed URL:', urlResponse.data.url);
            console.log('CLIENT: fileName of file created in S3:', urlResponse.data.file);
        } catch (error) {
            setIsLoading(false);
            console.error('Error in pre-signed URL request:', error);
            console.error('Error in pre-signed URL request:', error.data);
            setSnackbarMessage('Error in generating pre-signed URL');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            handleClear();
            setUploading(false);
            return; // Return early to stop execution
        }

        try {
            // Get the URL from the response
            const presignedUrl = urlResponse.data.url;
            console.log('Presigned URL: ', presignedUrl); // Log presigned URL

            // Use the pre-signed URL to upload the file to S3
            const formData = new FormData();
            formData.append('file', file);

            for (var pair of formData.entries()) {
                // Log form data entries
                console.log(pair[0] + ', ' + pair[1]);
            }

            console.log(
                'File details:',
                urlResponse.data.file.name,
                urlResponse.data.file.size,
                urlResponse.data.file.type
            );
            const uploadResponse = await axios.put(presignedUrl, formData);

            console.log('Upload Response:', uploadResponse); // Log upload response

            // Mark as uploaded
            uploaded = true;
        } catch (error) {
            setIsLoading(false);
            console.error('Error in file upload:', error);
            console.error('Error details:', error.response.data); // Log error response data
            handleClear();
            setUploading(false);
            setSnackbarMessage('Error in file upload: pre-signed URL request failed');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return; // Return early to stop execution
        }

        if (uploaded) {
            try {
                // Notify the server that the upload is complete
                console.log('user.sub', user.sub);
                console.log('user', user);
                const response = await axios.post(
                    `${backendUrl}/notesGeneration/v1/notes-generation`,
                    {
                        fileName: urlResponse.data.file,
                        docName,
                        description,
                        userId: user.sub
                    }
                );
                setSnackbarMessage('Request submitted!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            } catch (error) {
                setIsLoading(false);
                // console.error('Error in /notes-generation request:', error);
                setSnackbarMessage('Error in Notes Generation stage!');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);

                // If the file was uploaded but the /notes-generation request failed, delete the file from S3
                try {
                    await axios.post(
                        `${backendUrl}/deleteFile/v1/delete-file`,
                        {
                            fileName: urlResponse.data.file,
                        }
                    );
                } catch (deleteError) {
                    console.error('Error deleting file:', deleteError);
                }
            }
        }

        handleClear();
        setUploading(false);
        setIsLoading(false);
    };


    /**
     * Render the component.
     */
    return (
        <>
            <IconButton
                sx={{ top: 0, left: 0 }}
                onClick={() => { navigate('/create-notes-options'); }}
            >
                <ArrowBackIcon />
            </IconButton>
            <Container maxWidth="sm">
                <Box sx={{ marginTop: 4, position: 'relative' }} >
                    <h1>Upload audio for notes generation</h1>
                    <Typography variant="body1" align="center" sx={{ marginBottom: 3 }}>
                        Upload your audio file, create a document name and description, and submit it to our app. You will be notified by email when your notes are ready.
                        Please note that it may take up to 15 minutes for your notes to be ready.
                        We support all common media formats, such as MP3, MP4, Ogg, WAV, PCM and FLAC and many more.
                        For best results, use a lossless format such as FLAC or ALAC, or a lossy format like MP3 or AAC with a bitrate of 192 Kbps or above.
                        After clicking submit, wait patiently until you see an alert saying that your request
                        was submitted successfully, then you may close the tab and monitor the history page to download
                        your notes once they are ready.
                    </Typography>

                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="document-name"
                                    label="Document Name"
                                    variant="outlined"
                                    fullWidth
                                    value={docName}
                                    onChange={handleDocNameChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="description"
                                    label="Description"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    fullWidth
                                    value={description}
                                    onChange={handleDescriptionChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledDropzone {...getRootProps()}>
                                    <Input {...getInputProps()} />
                                    {fileName ? (
                                        <React.Fragment>
                                            <Typography color="textPrimary">{fileName}</Typography>
                                            <Button color="secondary" onClick={handleClear}>Clear</Button>
                                        </React.Fragment>
                                    ) : (
                                        <Typography>
                                            Drag 'n' drop an audio file here, or click to select a file
                                        </Typography>
                                    )}
                                </StyledDropzone>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    disabled={!fileName || !docName}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>

                {isLoading && <SpinnerOverlay />}

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={() => setSnackbarOpen(false)}
                >
                    <Alert
                        onClose={() => setSnackbarOpen(false)}
                        severity={snackbarSeverity}
                        sx={{ width: '100%', fontSize: '1.5rem' }}
                        iconMapping={{
                            success: <CheckCircleIcon fontSize="large" />,
                            error: <ErrorIcon fontSize="large" />,
                            warning: <WarningIcon fontSize="large" />,
                            info: <InfoIcon fontSize="large" />
                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </>
    );
}

export default CreateNotes;
